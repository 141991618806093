import React from "react";
import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import WidgetsPage from "./pages/WidgetsPage";
import store from "./store";
import "./styles/widgets.css";
import { ProvideAxios } from "./utils/hook/useAxios";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ProvideAxios>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/trustboxes/:templateId" element={<WidgetsPage />} />
          </Routes>
        </Router>
      </Provider>
    </ProvideAxios>
  </React.StrictMode>
);
