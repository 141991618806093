import React from "react";

import moment from "moment";
import { useEffect, useState } from "react";
import star1 from "../../assets/star-1.svg";
import star2 from "../../assets/star-2.svg";
import star3 from "../../assets/star-3.svg";
import star4 from "../../assets/star-4.svg";
import star5 from "../../assets/star-5.svg";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/owl.carousel.css";
import { useAxios } from "../../utils/hook/useAxios";

function ReviewItemWidget({ review }) {
  const stars = [star1, star2, star3, star4, star5];
  const axios = useAxios();
  const [hash_transaction, setHash_transaction] = useState(null);

  useEffect(() => {
    axios
      .get(`/data/transaction-get/${review.job_id}`)
      .then((res) => {
        setHash_transaction(res.data.hash_transaction);
        console.log(res.data);
      })
      .catch((error) => {});
    fetch(
      "https://api.veritatrust.com/v1/data/transaction-get/" + review.job_id
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.json();
      })
      .then((data) => {
        console.log(data);
        setHash_transaction(data.hash_transaction);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  }, [, review.id]);

  return (
    <>
      <div className="swiper-slide p-4 d-flex flex-column">
        <header className="d-flex justify-content-between flex-wrap">
          <img
            className="mb-1"
            src={stars[review?.rating - 1]}
            alt="Veritatrust"
            height="30"
            width="125"
          />
          <span className="published mb-2">
            {/* Published on <br />{" "}
            {moment(review?.createdAt).format("MMM DD, YYYY")}{" "} */}
            <a
              href={
                "https://testnet.ccdscan.io/transactions?dcount=1&dentity=transaction&dhash=" +
                hash_transaction
              }
              target="_blank"
              rel="noreferrer"
              title="view on blockchain"
            >
              <i className="flaticon-cube-3d text-success"></i> verified
            </a>
          </span>
        </header>
        <div className="content mb-2">
          <p className="text-primary">
            {review?.content.length <= 180
              ? review?.content
              : review?.content.slice(0, 179) + "..."}
          </p>
        </div>
        <div className="author text-muted">
          <p>
            <strong>
              {review.nickName != null ? review?.nickName : "Anonymous"}
            </strong>
            , published on {moment(review?.updatedAt).format("MMM DD, YYYY")}
          </p>
        </div>
      </div>
    </>
  );
}

export default ReviewItemWidget;
