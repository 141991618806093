import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import LogoVt from "../../assets/logo.png";
import star1 from "../../assets/star-1.svg";
import star2 from "../../assets/star-2.svg";
import star3 from "../../assets/star-3.svg";
import star4 from "../../assets/star-4.svg";
import star5 from "../../assets/star-5.svg";
import ReviewItemWidget from "../../components/ReviewItemWidget";
import ReviewItemWidget2 from "../../components/ReviewItemWidget2";
import "../../styles/owl.carousel.css";
import "../../styles/widgets.css";
import { useAxios } from "../../utils/hook/useAxios";

const SkeletonWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
}));

const ReviewsSkeleton = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(1),
}));

const ReviewsNumberSkeleton = styled(Skeleton)(({ theme }) => ({
  width: 50,
  marginRight: theme.spacing(0.5),
}));

function WidgetsPage() {
  const [templateId, setTemplateId] = useState(null);
  const [businessunitId, setBusinessunitId] = useState(null);
  const [merchantData, setMerchantData] = useState({});
  const [reviews, setReviews] = useState([]);
  const [OnDomain, setOnDomain] = useState(false);
  const axios = useAxios();
  const notes = ["Bad", "Passable", "Average", "Good", "Excellent"];
  const stars = [star1, star2, star3, star4, star5];

  const [slidesToShowScroll, setSlideToShowScroll] = useState(0);

  var settings_template3 = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToShowScroll,
    slidesToScroll: slidesToShowScroll,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 4000,
    variableWidth: false,
    centerMode: false,
    swipeToSlide: true,
    arrows: true,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var settings_template4 = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 4000,
    variableWidth: false,
    centerMode: false,
    swipeToSlide: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(currentUrl);
    window.localStorage.setItem("langPage", "en");

    setTemplateId(urlParams.get("templateId"));

    // Move this inside the then block
    fetch(
      "https://api.veritatrust.com/v1/merchant_profiles/findmerchant/" +
        urlParams.get("businessunitId")
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.json();
      })
      .then((data) => {
        console.log("businessunitId", urlParams.get("businessunitId"));
        console.log("templateId", urlParams.get("templateId"));

        setBusinessunitId(urlParams.get("businessunitId"));
        setTemplateId(urlParams.get("templateId"));
        setMerchantData(data);
        setSlideToShowScroll(
          data.ReviewsNumber >= 4 ? 4 : data.ReviewsNumber >= 3 ? 3 : 2
        );
        const referrer = document.referrer;
        console.log("referrer", referrer);
        console.log(
          "merchant",
          data?.website?.replace(/^https?:\/\//, "").replace(/^www\./, "")
        );
        if (
          referrer
            ?.replace(/^https?:\/\//, "")
            .replace(/^www\./, "")
            .includes(
              data?.website?.replace(/^https?:\/\//, "").replace(/^www\./, "")
            )
        ) {
          setOnDomain(true);
        } else {
          setOnDomain(false);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });

    window.iFrameResizer = {
      targetOrigin: "https://" + merchantData.website,
    };
  }, []);

  useEffect(() => {
    // axios
    //   .get(`/organic-merchant-review/${merchantData.website}`)
    //   .then((res) => {
    //     setReviews(res.data.filter((review) => review.addShowCase == "1"));
    //   })
    //   .catch((error) => {});

    let fetchReviews = async () => {
      try {
        const reviewdata = await fetch(
          "https://api.veritatrust.com/v1/support/merchantreviews/v2?merchant_id=" +
            merchantData.id
        );
        const reviewJson = await reviewdata.json();

        const reviewdata2 = await fetch(
          "https://api.veritatrust.com/v1/support/productreviews/V2?merchant_id=" +
            merchantData.id
        );
        const reviewJson2 = await reviewdata2.json();

        setReviews(
          reviewJson
            .concat(reviewJson2)
            .sort((a, b) => b.review.updatedAt - a.review.updatedAt)
            .filter((r) => r.review.status === "published")
        );
        let filteredReviews = reviewJson
          .concat(reviewJson2)
          .sort((a, b) => b.review.updatedAt - a.review.updatedAt)
          .filter((r) => r.review.status === "published");

        setReviews(filteredReviews.filter((a) => a.review.addShowCase == "1"));
      } catch (error) {}
    };
    fetchReviews();
  }, [, merchantData.id]);

  const calculateContentHeight = () => {
    const body = document.body;
    const html = document.documentElement;

    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    return height;
  };

  const sendHeightToParent = () => {
    const contentHeight = calculateContentHeight();
    // Envoyer la hauteur à tous les domaines (*)
    setHeight(elementRef.current.offsetHeight);
    console.log("Off Height", height);
    window.parent.postMessage({ height: height, templateId: templateId }, "*");
  };

  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (elementRef.current) setHeight(elementRef.current.offsetHeight);
    };
    window.addEventListener("message", handleResize);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    sendHeightToParent(); // Exécution lors du chargement initial

    // Exécuter 3 fois de plus avec 1 seconde d'intervalle
    const timeouts = [];
    for (let i = 1; i <= 4; i++) {
      const timeout = setTimeout(() => {
        sendHeightToParent();
      }, i * 1000);
      timeouts.push(timeout);
    }

    // Nettoyage des timeouts lorsque le composant est démonté
    return () => {
      timeouts.forEach(clearTimeout);
    };
  }, [, height]); // Dépendance sur la hauteur

  return (
    <div ref={elementRef}>
      {/* Display widget 1 */}
      {/* {templateId == 1 && (
        <div
          class="global_link d-flex align-items-center flex-column"
          id={"template-" + templateId}
        >
          <img src={LogoVt} alt="Veritatrust" height="39" width="200" />
          <img
            src={stars[Math.floor(merchantData?.ReviewMean)]}
            alt="reviews"
            height="50"
            width="190"
          />
          <div class="d-flex justify-content-center">
            <span class="reviews_number">
              <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
            </span>{" "}
            –{" "}
            <a
              href={`https://api.veritatrust.com/${window.localStorage.getItem(
                "langPage"
              )}/review-merchant/${merchantData?.website}`}
              target="_blank"
              rel="noreferrer"
            >
              <span class="reviews_number">
                <strong>{merchantData?.ReviewsNumber}</strong> reviews
              </span>
            </a>
          </div>
        </div>
      )} */}
      {templateId == 1 && (
        <div
          className="global_link d-flex align-items-center flex-column"
          id={"template-" + templateId}
        >
          {OnDomain ? (
            <>
              <img src={LogoVt} alt="Veritatrust" height="39" width="200" />
              <img
                src={stars[Math.floor(merchantData?.ReviewMean)]}
                alt="reviews"
                height="50"
                width="190"
              />
              <div className="d-flex justify-content-center">
                <span className="reviews_number">
                  <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
                </span>{" "}
                –{" "}
                <a
                  href={`https://app.veritatrust.com/${window.localStorage.getItem(
                    "langPage"
                  )}/review-merchant/${merchantData?.website}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="reviews_number">
                    <strong>{merchantData?.ReviewsNumber}</strong> reviews
                  </span>
                </a>
              </div>
            </>
          ) : (
            <SkeletonWrapper>
              <Skeleton variant="rectangular" width={200} height={39} />
              <Skeleton
                variant="rectangular"
                width={190}
                height={50}
                style={{ marginTop: "10px" }}
              />
              <ReviewsSkeleton>
                <ReviewsNumberSkeleton variant="text" />
                <Skeleton variant="text" width={10} />
                <Skeleton
                  variant="text"
                  width={70}
                  style={{ marginLeft: "10px" }}
                />
              </ReviewsSkeleton>
            </SkeletonWrapper>
          )}
        </div>
      )}
      {/* Display widget 2 */}
      {/* {templateId == 2 && (
        <div
          className="global_link d-md-flex align-items-center justify-content-center"
          id={"template-" + templateId}
        >
          <div className="d-flex align-items-center justify-content-center">
            <img src={LogoVt} alt="Veritatrust" height="29" width="150" />{" "}
            <p className="reviews_number m-0 me-2">
              <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
            </p>
          </div>
          <a
            href={`https://api.veritatrust.com/${window.localStorage.getItem(
              "langPage"
            )}/review-merchant/${merchantData?.website}`}
            className="d-flex justify-content-center align-items-center"
            target="_blank"
            rel="noreferrer"
          >
            <p className="reviews_number m-0 ms-2">
              Read our <strong>{merchantData?.ReviewsNumber}</strong>
              reviews <strong>›</strong>
            </p>
          </a>
        </div>
      )} */}
      {templateId == 2 && (
        <div
          className="global_link d-md-flex align-items-center justify-content-center"
          id={"template-" + templateId}
        >
          {OnDomain ? (
            <div className="d-flex align-items-center justify-content-center">
              <img src={LogoVt} alt="Veritatrust" height="29" width="150" />{" "}
              <p className="reviews_number m-0 me-2">
                <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
              </p>
              <a
                href={`https://app.veritatrust.com/${window.localStorage.getItem(
                  "langPage"
                )}/review-merchant/${merchantData?.website}`}
                className="d-flex justify-content-center align-items-center"
                target="_blank"
                rel="noreferrer"
              >
                <p className="reviews_number m-0 ms-2">
                  Read our <strong>{merchantData?.ReviewsNumber}</strong>
                  reviews <strong>›</strong>
                </p>
              </a>
            </div>
          ) : (
            <SkeletonWrapper className="d-md-flex align-items-center justify-content-center">
              <Skeleton variant="rectangular" width={150} height={29} />
              <Skeleton
                variant="text"
                width={50}
                height={29}
                style={{ marginLeft: "10px" }}
              />
              <Skeleton
                variant="text"
                width={150}
                height={29}
                style={{ marginLeft: "10px" }}
              />
            </SkeletonWrapper>
          )}
        </div>
      )}
      {/* Display widget 3 */}
      {/* {templateId == 3 && (
        <div class="reviews position-relative" id={"template-" + templateId}>
          <section className="d-md-flex">
            <div class="first global_link d-flex align-content-center justify-content-center flex-column align-items-center">
              <img src={LogoVt} alt="Veritatrust" height="29" width="150" />
              <p class="mb-1">
                <span class="reviews_number">
                  <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
                </span>{" "}
                -{" "}
                <span class="reviews_number">
                  {notes[Math.floor(merchantData?.ReviewMean?.toFixed(1)) - 1]}
                </span>
              </p>
              <a
                href={`https://api.veritatrust.com/${window.localStorage.getItem(
                  "langPage"
                )}/review-merchant/${merchantData?.website}`}
                target="_blank"
                rel="noreferrer"
              >
                <span class="reviews_number">
                  Read our <strong>{merchantData?.ReviewsNumber}</strong>{" "}
                  reviews
                </span>
              </a>
              <span class="reviews_number small">
                Trusted Reviews on the Blockchain
              </span>
            </div>
            <div class="carrousel">
              <Slider {...settings_template3}>
                {reviews.map((review) => (
                  <ReviewItemWidget
                    key={review.review.id}
                    review={review.review}
                  ></ReviewItemWidget>
                ))}
              </Slider>
            </div>
          </section>
        </div>
      )} */}
      {templateId == 3 && (
        <div
          className="reviews position-relative"
          id={"template-" + templateId}
        >
          <section className="d-md-flex">
            <div className="first global_link d-flex align-content-center justify-content-center flex-column align-items-center">
              {OnDomain ? (
                <>
                  <img src={LogoVt} alt="Veritatrust" height="29" width="150" />
                  <p className="mb-1">
                    <span className="reviews_number">
                      <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
                    </span>{" "}
                    -{" "}
                    <span className="reviews_number">
                      {
                        notes[
                          Math.floor(merchantData?.ReviewMean?.toFixed(1)) - 1
                        ]
                      }
                    </span>
                  </p>
                  <a
                    href={`https://app.veritatrust.com/${window.localStorage.getItem(
                      "langPage"
                    )}/review-merchant/${merchantData?.website}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="reviews_number">
                      Read our <strong>{merchantData?.ReviewsNumber}</strong>{" "}
                      reviews
                    </span>
                  </a>
                  <span className="reviews_number small">
                    Trusted Reviews on the Blockchain
                  </span>
                </>
              ) : (
                <>
                  <Skeleton variant="rectangular" width={150} height={29} />
                  <Skeleton
                    variant="text"
                    width={60}
                    height={20}
                    style={{ marginTop: "10px" }}
                  />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={20}
                    style={{ marginTop: "10px" }}
                  />
                  <Skeleton
                    variant="text"
                    width={180}
                    height={20}
                    style={{ marginTop: "10px" }}
                  />
                </>
              )}
            </div>
            <div className="carrousel">
              {OnDomain ? (
                <Slider {...settings_template3}>
                  {reviews.map((review) => (
                    <ReviewItemWidget
                      key={review.review.id}
                      review={review.review}
                    ></ReviewItemWidget>
                  ))}
                </Slider>
              ) : (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Grid container spacing={2}>
                      {[1, 2, 3, 4].map((index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                mb: 1,
                              }}
                            >
                              {[1, 2, 3, 4, 5].map((star) => (
                                <Skeleton
                                  key={star}
                                  variant="circular"
                                  width={20}
                                  height={20}
                                  sx={{ mx: 0.5 }}
                                />
                              ))}
                            </Box>
                            <Skeleton
                              variant="rectangular"
                              width={210}
                              height={118}
                            />
                            <Skeleton
                              variant="text"
                              width={180}
                              sx={{ mt: 1 }}
                            />
                            <Skeleton
                              variant="text"
                              width={140}
                              sx={{ mt: 1 }}
                            />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </>
              )}
            </div>
          </section>
        </div>
      )}

      {/* Widget 4 display */}
      {/* {templateId == 4 && (
        <div class="reviews position-relative" id={"template-" + templateId}>
          <section class="">
            <div class="first global_link d-flex align-content-center justify-content-center flex-column w-auto align-items-center mx-auto mb-4">
              <img src={LogoVt} alt="Veritatrust" height="29" width="150" />

              <p class="mb-1">
                <span class="reviews_number">
                  <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
                </span>{" "}
                -{" "}
                <span class="reviews_number">
                  {" "}
                  {notes[Math.floor(merchantData?.ReviewMean?.toFixed(1)) - 1]}
                </span>
              </p>
              <a
                href={`https://api.veritatrust.com/${window.localStorage.getItem(
                  "langPage"
                )}/review-merchant/${merchantData?.website}`}
                target="_blank"
                rel="noreferrer"
              >
                <span class="reviews_number">
                  Read our <strong>{merchantData?.ReviewsNumber}</strong>{" "}
                  reviews
                </span>
              </a>
              <span class="reviews_number small">
                Trusted Reviews on the Blockchain
              </span>
            </div>

            <div class="carrousel">
              <Slider {...settings_template4}>
                {reviews.slice(0, 15).map((review) => (
                  <ReviewItemWidget
                    key={review.review.id}
                    review={review.review}
                  ></ReviewItemWidget>
                ))}
              </Slider>
            </div>
          </section>
        </div>
      )} */}
      {templateId == 4 && (
        <div
          className="reviews position-relative"
          id={"template-" + templateId}
        >
          <section>
            <div className="first global_link d-flex align-content-center justify-content-center flex-column w-auto align-items-center mx-auto mb-4">
              {OnDomain ? (
                <>
                  <img src={LogoVt} alt="Veritatrust" height="29" width="150" />
                  <p className="mb-1">
                    <span className="reviews_number">
                      <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
                    </span>{" "}
                    -{" "}
                    <span className="reviews_number">
                      {
                        notes[
                          Math.floor(merchantData?.ReviewMean?.toFixed(1)) - 1
                        ]
                      }
                    </span>
                  </p>
                  <a
                    href={`https://app.veritatrust.com/${window.localStorage.getItem(
                      "langPage"
                    )}/review-merchant/${merchantData?.website}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="reviews_number">
                      Read our <strong>{merchantData?.ReviewsNumber}</strong>{" "}
                      reviews
                    </span>
                  </a>
                  <span className="reviews_number small">
                    Trusted Reviews on the Blockchain
                  </span>
                </>
              ) : (
                <>
                  <Skeleton variant="rectangular" width={150} height={29} />
                  <Skeleton
                    variant="text"
                    width={60}
                    height={20}
                    style={{ marginTop: "10px" }}
                  />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={20}
                    style={{ marginTop: "10px" }}
                  />
                  <Skeleton
                    variant="text"
                    width={180}
                    height={20}
                    style={{ marginTop: "10px" }}
                  />
                </>
              )}
            </div>

            <div className="carrousel">
              {OnDomain ? (
                <Slider {...settings_template4}>
                  {reviews.slice(0, 15).map((review) => (
                    <ReviewItemWidget
                      key={review.review.id}
                      review={review.review}
                    ></ReviewItemWidget>
                  ))}
                </Slider>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid container spacing={2}>
                      {[1, 2, 3, 4].map((index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                mb: 1,
                              }}
                            >
                              {[1, 2, 3, 4, 5].map((star) => (
                                <Skeleton
                                  key={star}
                                  variant="circular"
                                  width={20}
                                  height={20}
                                  sx={{ mx: 0.5 }}
                                />
                              ))}
                            </Box>
                            <Skeleton
                              variant="rectangular"
                              width={210}
                              height={118}
                            />
                            <Skeleton
                              variant="text"
                              width={180}
                              sx={{ mt: 1 }}
                            />
                            <Skeleton
                              variant="text"
                              width={140}
                              sx={{ mt: 1 }}
                            />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              )}
            </div>
          </section>
        </div>
      )}

      {/* Widget 5 display */}
      {/* {templateId == 5 && (
        <section class="container" id={"template-" + templateId}>
          <div class="row mb-3">
            <div class="first d-flex align-content-center justify-content-center flex-column mx-auto">
              <img src={LogoVt} alt="Veritatrust" height="29" width="150" />
              <p class="mb-1">
                <span class="reviews_number">
                  <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
                </span>{" "}
                -{" "}
                <span class="reviews_number">
                  {" "}
                  {notes[Math.floor(merchantData?.ReviewMean?.toFixed(1)) - 1]}
                </span>
              </p>
              <a
                href={`https://api.veritatrust.com/${window.localStorage.getItem(
                  "langPage"
                )}/review-merchant/${merchantData?.website}`}
                target="_blank"
                rel="noreferrer"
              >
                <span class="reviews_number">
                  Read our <strong>{merchantData?.ReviewsNumber}</strong>{" "}
                  reviews
                </span>
              </a>
              <span class="reviews_number small">
                Trusted Reviews on the Blockchain
              </span>
            </div>
          </div>
          <p class="text-end text-muted">Our 4 & 5 star reviews</p>
          <div class="row mansory" data-masonry='{"percentPosition": true }'>
            {reviews.slice(0, 15).map((review) => (
              <ReviewItemWidget2
                key={review.review.id}
                review={review.review}
              ></ReviewItemWidget2>
            ))}
          </div>
        </section>
      )} */}
      {templateId == 5 && (
        <section className="container" id={"template-" + templateId}>
          <div className="row mb-3">
            <div className="first d-flex align-content-center justify-content-center flex-column mx-auto">
              {OnDomain ? (
                <>
                  <img src={LogoVt} alt="Veritatrust" height="29" width="150" />
                  <p className="mb-1">
                    <span className="reviews_number">
                      <strong>{merchantData?.ReviewMean?.toFixed(1)}</strong>/5
                    </span>{" "}
                    -{" "}
                    <span className="reviews_number">
                      {
                        notes[
                          Math.floor(merchantData?.ReviewMean?.toFixed(1)) - 1
                        ]
                      }
                    </span>
                  </p>
                  <a
                    href={`https://app.veritatrust.com/${window.localStorage.getItem(
                      "langPage"
                    )}/review-merchant/${merchantData?.website}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="reviews_number">
                      Read our <strong>{merchantData?.ReviewsNumber}</strong>{" "}
                      reviews
                    </span>
                  </a>
                  <span className="reviews_number small">
                    Trusted Reviews on the Blockchain
                  </span>
                </>
              ) : (
                <>
                  <Skeleton variant="rectangular" width={150} height={29} />
                  <Skeleton
                    variant="text"
                    width={60}
                    height={20}
                    style={{ marginTop: "10px" }}
                  />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={20}
                    style={{ marginTop: "10px" }}
                  />
                  <Skeleton
                    variant="text"
                    width={180}
                    height={20}
                    style={{ marginTop: "10px" }}
                  />
                </>
              )}
            </div>
          </div>
          <p className="text-end text-muted">Our 4 & 5 star reviews</p>
          <div
            className="row mansory"
            data-masonry='{"percentPosition": true }'
          >
            {OnDomain ? (
              reviews
                .slice(0, 15)
                .map((review) => (
                  <ReviewItemWidget2
                    key={review.review.id}
                    review={review.review}
                  ></ReviewItemWidget2>
                ))
            ) : (
              <Box sx={{ width: "100%" }}>
                <Grid container spacing={2}>
                  {Array.from(new Array(15)).map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            mb: 1,
                          }}
                        >
                          {[1, 2, 3, 4, 5].map((star) => (
                            <Skeleton
                              key={star}
                              variant="circular"
                              width={20}
                              height={20}
                              sx={{ mx: 0.5 }}
                            />
                          ))}
                        </Box>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={118}
                        />
                        <Skeleton variant="text" width="80%" sx={{ mt: 1 }} />
                        <Skeleton variant="text" width="60%" sx={{ mt: 1 }} />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </div>
        </section>
      )}
    </div>
  );
}

export default WidgetsPage;
